// Import necessary types from the libraries
import { Box, Card } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import MaxSizeLoading from "components/MaxSizeLoading";
import PageLayout from "components/PageLayout";
import { useAllStatisticsSubscription } from "models/Statistics";
import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";

// Define statistics types
interface Location {
  lat: number;
  lng: number;
}

interface Statistic {
  createdAt: { seconds: number };
  activePatients: number;
  activePrescriptions: number;
  locations: Location[];
  patientsByAgeBreakdown: { [key: string]: number };
  prescriptionsByMedicationBreakdown: { [key: string]: number };
  prescriptionsByMedicationAndStrengthBreakdown: { [key: string]: number };
  prescriptionsByPrescriberBreakdown: { [key: string]: number };
  prescriptionsByFrequency: { [key: string]: number };
}

// Dynamic import of the Chart component with TypeScript support
const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

const DashboardPage: React.FC = () => {
  // Define the state for statistics and the reference for the map
  const [statistics, setStatistics] = useState<Statistic[] | undefined>(
    undefined,
  );
  const map = useRef<google.maps.Map | null>(null);

  // Subscribe to the statistics data
  useAllStatisticsSubscription(setStatistics);

  useEffect(() => {
    async function initMap() {
      if (!statistics) return;
      if (typeof google !== "undefined" && !map.current) {
        const { Map } = (await google.maps.importLibrary(
          "maps",
        )) as google.maps.MapsLibrary;
        const { AdvancedMarkerElement } = (await google.maps.importLibrary(
          "marker",
        )) as google.maps.MarkerLibrary;

        map.current = new Map(document.getElementById("map") as HTMLElement, {
          mapId: "map",
        });

        const serviceLocations = statistics[0].locations;

        const bounds = new google.maps.LatLngBounds();
        const markers: InstanceType<typeof AdvancedMarkerElement>[] = []; // Correct type usage

        for (let index = 0; index < serviceLocations.length; index++) {
          const latLng = serviceLocations[index];
          const marker = new AdvancedMarkerElement({
            position: latLng,
            map: map.current,
          });
          markers.push(marker);
          bounds.extend(latLng);
        }

        map.current.fitBounds(bounds);
      }
    }

    initMap();
  }, [statistics]);

  // If the statistics data isn't loaded, show a loading component
  if (!statistics) {
    return <MaxSizeLoading />;
  }

  return (
    <PageLayout breadcrumbs={[{ title: "Dashboard", href: "/" }]}>
      <Grid container spacing={3} paddingY={3}>
        <Grid xs={12}>
          <Card raised elevation={24} sx={{ padding: 0 }}>
            <Box id="map" width={"100%"} minHeight={600} />
          </Card>
        </Grid>
        <Grid xs={12}>
          <Card raised elevation={24} sx={{ padding: 3 }}>
            <Chart
              type="line"
              options={{
                xaxis: { type: "datetime" },
                title: {
                  text: "Active Injection Patients",
                  style: {
                    fontSize: "16px",
                  },
                },
              }}
              series={[
                {
                  name: "Patients",
                  data: statistics.map((statistic) => [
                    statistic.createdAt.seconds * 1000,
                    statistic.activePatients,
                  ]),
                },
              ]}
            />
          </Card>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default DashboardPage;
